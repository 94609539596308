import { Face } from '@mui/icons-material';
import { Chip, Paper, useTheme } from '@mui/material';
import { TPlayers } from '../../utils/types';

export default function Player({ estimate }: { estimate: TPlayers }) {
    const theme = useTheme();
    return (
        <Paper
            variant="outlined"
            elevation={0}
            sx={{
                width: 120,
                height: 150,
                display: 'flex',
                margin: theme.spacing(2),
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Chip
                icon={<Face />}
                label={estimate.playerId}
                color="primary"
                variant="outlined"
                sx={{
                    marginTop: theme.spacing(1),
                    width: '85%',
                    justifyContent: 'start',
                    fontSize: 14,
                }}
            />
            <Paper
                variant="outlined"
                elevation={0}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '85%',
                    height: '100%',
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                    fontSize: 64,
                    textAlign: 'center',
                    fontFamily: 'monospace',
                    color: estimate.status,
                }}
            >
                {estimate.estimate}
            </Paper>
        </Paper>
    );
}
