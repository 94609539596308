import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { Realtime } from 'ably';
import { AblyProvider, ChannelProvider } from 'ably/react';
import queryString from 'query-string';
import AdminDashboard from './AdminDashboard';
import { getRandomString } from '../../utils/util';

enum UI {
    Init,
    Ready,
}

export default function AdminLogin() {
    const channelIdRef = useRef<string>('');
    const navigate = useNavigate();
    const initialized = useRef(false);

    const [ui, setUi] = useState(UI.Init);
    const [channelId, setChannelId] = useState('');
    const [client, setClient] = useState<undefined | Realtime>(undefined);

    useEffect(() => {
        if (!initialized.current) {
            const hashParam = queryString.parse(window.location.hash) as {
                channelId: string;
                adminId: string;
            };

            if (!hashParam.channelId) {
                hashParam.channelId = getRandomString();
            }

            if (!hashParam.adminId) {
                hashParam.adminId = getRandomString();
            }

            navigate(
                `/admin#channelId=${hashParam.channelId}&adminId=${hashParam.adminId}`
            );

            channelIdRef.current = hashParam.channelId;

            setChannelId(hashParam.channelId);

            const client = new Realtime({
                authUrl: `${location.protocol}//${location.host}/api/token`,
                authParams: {
                    channelId: hashParam.channelId,
                    adminId: hashParam.adminId,
                },
            });
            setClient(client);

            setUi(UI.Ready);

            initialized.current = true;
        }

        return () => {
            if (client?.connection.state == 'connected') {
                client.connection.close();
                client.close();
            }
        };
    }, []);

    return (
        <Container sx={{ marginTop: 4 }}>
            {ui == UI.Init && (
                <Typography mt={5} sx={{ textAlign: 'center' }} variant="h3">
                    Loading...
                </Typography>
            )}
            {ui == UI.Ready && (
                <AblyProvider client={client}>
                    <ChannelProvider channelName={`admin:${channelId}`}>
                        <ChannelProvider channelName={`player:${channelId}`}>
                            <AdminDashboard
                                channelId={channelId}
                                adminChannelId={`admin:${channelId}`}
                                playerChannelId={`player:${channelId}`}
                            />
                        </ChannelProvider>
                    </ChannelProvider>
                </AblyProvider>
            )}
        </Container>
    );
}
