import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import AppbarDesktop from './AppbarDesktop';
import AppbarMobile from './AppbarMobile';
import { TMenu } from '../../utils/types';

const TITLE: TMenu = { to: '/', label: 'BoringPoker' };
const HOME_MENU: TMenu[] = [{ to: '/', label: 'Home' }];
const ADMIN_MENU: TMenu[] = [{ to: '/admin', label: 'Admin' }];
const PLAYER_MENU: TMenu[] = [{ to: '/player', label: 'Player' }];

export default function Appbar(): React.ReactElement {
    const location = useLocation();
    const theme = useTheme();
    const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [menu, setMenu] = useState<TMenu[]>(HOME_MENU);

    useEffect(() => {
        switch (true) {
            case location.pathname.startsWith('/admin'):
                setMenu(ADMIN_MENU);
                break;
            case location.pathname.startsWith('/player'):
                setMenu(PLAYER_MENU);
                break;
            default:
                setMenu(HOME_MENU);
        }
    }, [location.pathname]);

    return (
        <>
            {smallDevice && <AppbarMobile title={TITLE} menu={menu} />}
            {!smallDevice && <AppbarDesktop title={TITLE} menu={menu} />}
        </>
    );
}
