import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';

export default function HomePage() {
    const navigate = useNavigate();

    return (
        <Container sx={{ marginTop: 4 }}>
            <Typography mt={5} sx={{ textAlign: 'center' }} variant="h3">
                !!! Welcome to Boring Poker !!!
            </Typography>
            <Box
                mt={5}
                display={'flex'}
                justifyContent={'center'}
                sx={{ fontSize: 20 }}
            >
                Boring Poker is simple app to put estimate on your tasks during
                scrum planning.
            </Box>
            <Box mt={3} display={'flex'} justifyContent={'center'}>
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => navigate('/admin')}
                >
                    Create Poker Table
                </Button>
            </Box>

            <Box mt={3}>
                <Typography variant="body1" align="justify">
                    Boring Poker is a very boring simple app to put estimates on
                    your tasks during scrum planning. Person who start the game
                    is known as Admin. You can click on&nbsp;
                    <span style={{ color: 'green' }}>Create Poker Table</span>
                    &nbsp; button to start the game. This will create a new
                    poker table. Admin can share the link displayed on dashbaord
                    with team members. Using this link team member can join the
                    same table. Admin will see all the players card on his
                    dashbaord, these cards will auto revealed once all player
                    finished voting. Admin also have privilege to reveal cards
                    forcefully. Players will see voting button which they can
                    use to put estimate. Players will see the other players
                    card, but they are not revealed until everyone voted, or
                    admin use his special privilege to reveal cards. Once a
                    round of play is complete, Admin can start a new round by
                    clicking on Reset button.
                </Typography>
            </Box>
        </Container>
    );
}
