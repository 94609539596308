import { useEffect, useState } from 'react';
import { ContentCopyTwoTone } from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    Container,
    InputAdornment,
    Paper,
    TextField,
} from '@mui/material';
import { useChannel, usePresenceListener } from 'ably/react';
import Player from './Player';
import { BPC } from '../../utils/const';
import { TPlayers } from '../../utils/types';

function getPlayers(players: TPlayers[], reveal: boolean): TPlayers[] {
    if (reveal) return players;
    return players.map((p) => ({
        playerId: p.playerId,
        estimate: p.status == 'green' ? '✓' : '?',
        status: p.status,
    }));
}

export default function AdminDashboard({
    channelId,
    adminChannelId,
    playerChannelId,
}: {
    channelId: string;
    adminChannelId: string;
    playerChannelId: string;
}) {
    const [players, setPlayers] = useState<TPlayers[]>([]);
    const [estimates, setEstimates] = useState<TPlayers[]>([]);

    const [reveal, setReveal] = useState(false);

    const [copyStatus, setCopyStatus] = useState('Copy');

    // listen to admin channel to reveal score
    const { channel: adminChannel } = useChannel(adminChannelId, BPC.PLAYERS);
    // register to presence
    const { presenceData } = usePresenceListener(playerChannelId);

    // Listen to player channel to get estimates.
    useChannel(playerChannelId, BPC.ESTIMATE, (message) => {
        console.log(BPC.ESTIMATE, message.data);
        const data: TPlayers = message.data;

        // score handling
        const player = estimates.find((x) => x.playerId === data.playerId);

        if (player) {
            setEstimates(
                estimates.map((x) =>
                    x.playerId == data.playerId
                        ? {
                              playerId: x.playerId,
                              estimate: data.estimate,
                              status: 'green',
                          }
                        : x
                )
            );
        } else {
            setEstimates([...estimates, data]);
        }
    });

    useEffect(() => {
        const clients = presenceData.map((x) => x.clientId);

        const playersWithStatus: TPlayers[] = [];

        clients.forEach((p) => {
            const s = estimates.find((s) => s.playerId === p);

            if (s) {
                s.status = 'green';
                playersWithStatus.push(s);
            } else {
                playersWithStatus.push({
                    playerId: p,
                    status: 'red',
                    estimate: '?',
                });
            }
        });

        setPlayers(playersWithStatus);

        adminChannel.publish({
            name: BPC.PLAYERS,
            data: getPlayers(playersWithStatus, false),
        });

        // Auto publish if everyone estimated
        if (estimates.length > 0 && estimates.length == presenceData.length) {
            setReveal(true);
            adminChannel.publish({
                name: BPC.PLAYERS,
                data: playersWithStatus,
            });
            adminChannel.publish({
                name: BPC.SHOW,
                data: { isDisabled: true },
            });
        }
    }, [estimates, presenceData]);

    const link = `${location.protocol}//${location.host}/player#channelId=${channelId}`;

    return (
        <Container sx={{ marginTop: 4 }}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignContent={'center'}
            >
                <TextField
                    sx={{
                        '& .MuiFormLabel-root.Mui-disabled': { color: 'green' },
                        '& .MuiInputBase-input': {
                            textFillColor: `${'green'} !important`,
                        },
                    }}
                    label={'Share This Link'}
                    variant="outlined"
                    fullWidth={true}
                    disabled={true}
                    value={link}
                    inputProps={{
                        style: {
                            fontSize: 'smaller',
                        },
                    }}
                    InputProps={{
                        style: {
                            height: '100%',
                            alignItems: 'baseline',
                            flexDirection: 'row',
                            flexFlow: 'row-reverse',
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 3,
                                    cursor: 'pointer',
                                    flexFlow: 'row-reverse',
                                }}
                                onClick={() => {
                                    navigator.clipboard
                                        .writeText(link)
                                        .then(() => setCopyStatus('Copied'));
                                    setTimeout(
                                        () => setCopyStatus('Copy'),
                                        5000
                                    );
                                }}
                            >
                                <ContentCopyTwoTone sx={{ color: 'green' }} />
                                <span
                                    style={{
                                        fontSize: 'smaller',
                                        color: 'black',
                                        marginRight: '8px',
                                    }}
                                >
                                    {copyStatus}
                                </span>
                            </InputAdornment>
                        ),
                    }}
                ></TextField>
                <Box mt={4} display={'flex'}>
                    <Alert
                        icon={false}
                        variant="filled"
                        severity="info"
                        sx={{ width: '100%', justifyContent: 'center' }}
                    >
                        Table Members
                    </Alert>
                </Box>
                <Box mt={1} display={'flex'} flexDirection={'row'}>
                    <Paper
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        {players.length == 0 && (
                            <Alert
                                severity="error"
                                variant="filled"
                                sx={{ marginTop: 2, marginBottom: 2 }}
                            >
                                No one wants to play Boring Poker :(
                            </Alert>
                        )}

                        {players.length > 0 &&
                            getPlayers(players, reveal).map((val, idx) => (
                                <Player estimate={val} key={idx} />
                            ))}
                    </Paper>
                </Box>

                {!reveal && (
                    <Box display={'flex'} justifyContent={'center'}>
                        <Button
                            onClick={() => {
                                setReveal(true);
                                adminChannel.publish({
                                    name: BPC.PLAYERS,
                                    data: players,
                                });
                                adminChannel.publish({
                                    name: BPC.SHOW,
                                    data: { isDisabled: true },
                                });
                            }}
                            type="submit"
                            variant="contained"
                            sx={{ mt: 5, width: 250 }}
                        >
                            Show Cards
                        </Button>
                    </Box>
                )}

                {reveal && (
                    <Box display={'flex'} justifyContent={'center'}>
                        <Button
                            onClick={() => {
                                setEstimates([]);
                                setReveal(false);
                                adminChannel.publish({
                                    name: BPC.SHOW,
                                    data: { isDisabled: false },
                                });
                            }}
                            type="submit"
                            variant="contained"
                            sx={{ mt: 5, width: 250 }}
                        >
                            Reset
                        </Button>
                    </Box>
                )}
            </Box>
        </Container>
    );
}
