import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Handyman as HandyManIcon } from '@mui/icons-material';
import {
    useTheme,
    AppBar,
    IconButton,
    Toolbar,
    Typography,
    Container,
    List,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import { TMenu } from '../../utils/types';

function AppbarDesktop({
    title,
    menu,
}: {
    title: TMenu;
    menu: TMenu[];
}): React.ReactElement {
    const theme = useTheme();
    const primaryLight = theme.palette.primary.light;
    const location = useLocation();

    return (
        <AppBar position="sticky">
            <Container fixed>
                <Toolbar sx={{ paddingLeft: '0', paddingRight: '0' }}>
                    <IconButton color="inherit" edge="start">
                        <HandyManIcon />
                    </IconButton>
                    <Typography
                        component={NavLink}
                        to={title.to}
                        variant="h6"
                        sx={{
                            textDecoration: 'inherit',
                            color: 'inherit',
                            marginRight: '2em',
                        }}
                    >
                        {title.label}
                    </Typography>
                    <List
                        component="nav"
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            textTransform: 'capitalize',
                            marginRight: 'auto',
                        }}
                    >
                        {menu.map((item, idx) => (
                            <ListItemButton
                                key={idx}
                                component={NavLink}
                                to={item.to}
                                sx={{
                                    background:
                                        location.pathname == item.to
                                            ? `${primaryLight}`
                                            : 'inherit',
                                    borderRadius: 2,
                                }}
                            >
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        ))}
                    </List>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default AppbarDesktop;
