import { Button, Paper, useTheme } from '@mui/material';

export default function VoteCard({
    label,
    isDisabled,
    publishScore,
}: {
    label: string;
    isDisabled: boolean;
    publishScore: (e: string) => void;
}) {
    const theme = useTheme();
    return (
        <Paper
            variant="outlined"
            elevation={0}
            sx={{
                width: '100px',
                height: '125px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: theme.spacing(1),
            }}
        >
            <Button
                disabled={isDisabled}
                variant="outlined"
                color="info"
                sx={{ fontSize: theme.spacing(4) }}
                onClick={() => publishScore(label)}
            >
                {label}
            </Button>
        </Paper>
    );
}
