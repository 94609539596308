import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Appbar from './components/AppBar/Appbar';
import AdminLogin from './components/BoringPoker/AdminLogin';
import HomePage from './components/BoringPoker/HomePage';
import PlayerLogin from './components/BoringPoker/PlayerLogin';
import Error from './components/Error/Error';

function AppRouter(): React.ReactElement {
    return (
        <BrowserRouter>
            <Appbar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/admin" element={<AdminLogin />} />
                <Route path="/player" element={<PlayerLogin />} />
                <Route
                    path="/*"
                    element={
                        <Error
                            errorcode="404"
                            title="Page Not Found"
                            message="We don't have what you looking for."
                        />
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;
