import { useState } from 'react';
import { Alert, Box, Container, Paper } from '@mui/material';
import { useChannel, usePresence } from 'ably/react';
import Player from './Player';
import VoteCard from './VoteCard';
import { BPC } from '../../utils/const';
import { TPlayers } from '../../utils/types';

const EST = ['½', '1', '2', '3', '5', '8', '13', '21', '?'];

export default function PlayerDashboard({
    playerId,
    adminChannelId,
    playerChannelId,
}: {
    playerId: string;
    adminChannelId: string;
    playerChannelId: string;
}) {
    const [isDisabled, setIsDisabled] = useState(false);
    const [players, setPlayers] = useState<TPlayers[]>([]);

    useChannel(adminChannelId, BPC.PLAYERS, (message) => {
        console.log(BPC.PLAYERS, message.data);
        const p: TPlayers[] = message.data;
        setPlayers(p);
    });

    useChannel(adminChannelId, BPC.SHOW, (message) => {
        console.log(BPC.SHOW, message.data);
        const p: { isDisabled: boolean } = message.data;
        setIsDisabled(p.isDisabled);
    });

    const { channel: playerChannel } = useChannel(playerChannelId);

    usePresence(playerChannelId);

    // callback passed to individual button to publish score
    function publishScore(esti: string) {
        const data = { playerId, estimate: esti, status: 'green' };

        console.log('Estimate: ', JSON.stringify(data));

        playerChannel.publish({
            name: BPC.ESTIMATE,
            data,
        });
    }

    return (
        <Container>
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignContent={'center'}
            >
                <Box mt={4} display={'flex'}>
                    <Alert
                        icon={false}
                        variant="filled"
                        severity="success"
                        sx={{ width: '100%', justifyContent: 'center' }}
                    >
                        Table Members
                    </Alert>
                </Box>
                <Box mt={1} display={'flex'} flexDirection={'row'}>
                    <Paper
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        {players.length == 0 && (
                            <Alert
                                severity="error"
                                variant="filled"
                                sx={{ marginTop: 2, marginBottom: 2 }}
                            >
                                No one wants to play Boring Poker :(
                            </Alert>
                        )}

                        {players.length > 0 &&
                            players.map((val, idx) => (
                                <Player estimate={val} key={idx} />
                            ))}
                    </Paper>
                </Box>
                <Box mt={4} display={'flex'}>
                    <Alert
                        icon={false}
                        variant="standard"
                        severity="warning"
                        sx={{ width: '100%', justifyContent: 'center' }}
                    >
                        Click on your estimate below.
                    </Alert>
                </Box>
                <Box mt={1} display={'flex'} flexDirection={'row'}>
                    <Paper
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        {EST.map((val, idx) => (
                            <VoteCard
                                key={idx}
                                label={val}
                                isDisabled={isDisabled}
                                publishScore={publishScore}
                            />
                        ))}
                    </Paper>
                </Box>
            </Box>
        </Container>
    );
}
