import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyleOutlined } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { Realtime } from 'ably';
import { AblyProvider, ChannelProvider } from 'ably/react';
import queryString from 'query-string';
import PlayerDashboard from './PlayerDashboard';

enum UI {
    Init,
    Ready,
}

function LoginForm({
    playerId,
    channelId,
    setPlayerId,
    startPlayerGame,
}: {
    playerId: string;
    channelId: string;
    setPlayerId: (s: string) => void;
    startPlayerGame: () => void;
}) {
    const [idInError, setIdInError] = useState(false);
    const [channelIdInError, setChannelIdInError] = useState(false);

    const handleSubmit = async () => {
        let validationError = false;

        if (playerId === '') {
            validationError = true;
            setIdInError(true);
        }

        if (channelId === '') {
            validationError = true;
            setChannelIdInError(true);
        }

        if (validationError) {
            return;
        } else {
            startPlayerGame();
        }
    };

    return (
        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
            <Box
                maxWidth={'sm'}
                component="form"
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <StyleOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Welcome To Boring Poker.
                </Typography>
                <Box width="100%" maxWidth={'md'} sx={{ mt: 3 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                                error={idInError}
                                helperText={
                                    idInError ? 'Player name is required' : ''
                                }
                                value={playerId}
                                onChange={(e) => setPlayerId(e.target.value)}
                                fullWidth
                                label="Player Name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={channelIdInError}
                                helperText={
                                    channelIdInError
                                        ? 'TableId is required.'
                                        : ''
                                }
                                disabled
                                value={channelId}
                                fullWidth
                                label="Table ID"
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Button
                    variant="contained"
                    sx={{ mt: 5, width: 300 }}
                    onClick={() => handleSubmit()}
                >
                    Join The Table
                </Button>
            </Box>
        </Box>
    );
}

export default function PlayerLogin() {
    const navigate = useNavigate();

    const [ui, setUi] = useState(UI.Init);
    const [channelId, setChannelId] = useState('');
    const [playerId, setPlayerId] = useState('');
    const [client, setClient] = useState<undefined | Realtime>(undefined);

    function startPlayerGame() {
        navigate(`/player#channelId=${channelId}&playerId=${playerId}`);

        const client = new Realtime({
            authUrl: `${location.protocol}//${location.host}/api/token`,
            authParams: {
                channelId: channelId,
            },
            clientId: playerId,
        });

        setClient(client);

        setUi(UI.Ready);
    }

    useEffect(() => {
        const hashParam = queryString.parse(window.location.hash) as {
            channelId: string;
            playerId: string;
        };

        if (hashParam.channelId) {
            setChannelId(hashParam.channelId);
        }

        if (hashParam.playerId) {
            setPlayerId(hashParam.playerId);
        }

        return () => {
            if (client?.connection.state == 'connected') {
                client.connection.close();
            }
        };
    }, []);

    return (
        <Container sx={{ marginTop: 4 }}>
            {ui == UI.Init && (
                <LoginForm
                    playerId={playerId}
                    channelId={channelId}
                    setPlayerId={setPlayerId}
                    startPlayerGame={startPlayerGame}
                />
            )}
            {ui == UI.Ready && (
                <AblyProvider client={client}>
                    <ChannelProvider channelName={`admin:${channelId}`}>
                        <ChannelProvider channelName={`player:${channelId}`}>
                            <PlayerDashboard
                                playerId={playerId}
                                adminChannelId={`admin:${channelId}`}
                                playerChannelId={`player:${channelId}`}
                            />
                        </ChannelProvider>
                    </ChannelProvider>
                </AblyProvider>
            )}
        </Container>
    );
}
