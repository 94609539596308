import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AllOut as AllOutIcon, Menu as MenuIcon } from '@mui/icons-material';
import {
    useTheme,
    AppBar,
    IconButton,
    Toolbar,
    Typography,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { TMenu } from '../../utils/types';

function MenuIcons({ idx }: { idx: number }): React.ReactElement {
    switch (idx) {
        case 0:
            return <AllOutIcon />;
        default:
            return <AllOutIcon />;
    }
}

function AppbarMobile({
    title,
    menu,
}: {
    title: TMenu;
    menu: TMenu[];
}): React.ReactElement {
    const drawerWidth = 240;
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    return (
        <>
            <AppBar position="sticky" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <div
                        id="avpa"
                        style={{
                            overflow: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            color="inherit"
                            onClick={() => setOpen(!open)}
                            edge="start"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            sx={{
                                textDecoration: 'inherit',
                                color: 'inherit',
                            }}
                        >
                            {title.label}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="persistent"
                anchor="left"
                open={open}
                classes={{ paper: 'width: 240px' }}
                sx={{
                    '& .MuiPaper-root': {
                        width: drawerWidth,
                    },
                    width: drawerWidth,
                    flexShrink: 0,
                    content: {
                        flexGrow: 1,
                        padding: theme.spacing(1),
                        transition: theme.transitions.create('margin', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                        marginLeft: -drawerWidth,
                    },
                    contentShift: {
                        transition: theme.transitions.create('margin', {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        marginLeft: 0,
                    },
                }}
            >
                <List component="nav" sx={{ paddingTop: theme.spacing(8) }}>
                    {menu.map((item, idx) => (
                        <ListItemButton
                            key={idx}
                            component={NavLink}
                            to={item.to}
                            onClick={() => setOpen(false)}
                        >
                            <ListItemIcon>
                                <MenuIcons idx={idx} />
                            </ListItemIcon>
                            <ListItemText
                                primary={item.label}
                                sx={{ marginLeft: -3 }}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </Drawer>
        </>
    );
}
export default AppbarMobile;
